import {initializeApp} from "firebase/app";
import {getFirestore, collection, doc} from "firebase/firestore";
import {getFunctions} from "firebase/functions";
// ... other firebase imports

export const firebaseApp = initializeApp({
	apiKey: "AIzaSyCgVSlvxvOjjftxcDZ45E0JbqZAaeLC4oQ",
	authDomain: "dma-scot.firebaseapp.com",
	projectId: "dma-scot",
	storageBucket: "dma-scot.appspot.com",
	messagingSenderId: "917484328336",
	appId: "1:917484328336:web:bc574e8fdac95e0e8bcf15",
	measurementId: "G-Y06BPZXF5G"
});

// used for the firestore refs
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp, "europe-west1");

export function getDocRef(collection, id) {
	return doc(db, collection, id);
}
export function getCollectionRef(collectionName) {
	return collection(db, collectionName);
}
