<template>
	<v-app-bar app color="white" flat>
		<v-toolbar-title class="font-weight-light d-none d-md-block"
			>Digital Maturity Assessment</v-toolbar-title
		>
		<v-spacer></v-spacer>
		
		<img
			align-with-title
			class="mt-4"
			:height="80"
			:src="require('@/assets/logo.png')" />
	</v-app-bar>
</template>
