import _ from "lodash";


const mw = ({store}) => {
	if (!store.options) {
		store.options = {
			type: null,
			collectionName: null,
			paginate: false,
			loadAll: false,
			query: false,
			limit: null,
			orderBy: null
		};
	}
	// store.status =  null;
	// store.error = null;
	// store.ref = null;

	if (process.env.NODE_ENV === "development") {
		store._customProperties.add("options").add("status").add("error");
	}
};

const mwReset = ({store}) => {
	const initialState = _.cloneDeep(store.$state);
	store.$reset = () => store.$patch(_.cloneDeep(initialState));
};

export {mw, mwReset};
