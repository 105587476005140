import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "landing",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LandingView.vue"),
  },
  {
    path: "/help",
    name: "help",
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HelpView.vue"),
  },
  {
    path: "/:id",
    name: "conference",
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ConferenceView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
